//src/api/brandapi.js
import request from '@/utils/request'

/**
 * POST 方法 与 GET方法  区别
 *
 * post==> data: obj
 * get==>params: obj
 *
 */
/*用户接口---------------------------*/
//获取用户列表接口
export function getmemberData(obj) {
    return request({
        url: '/api/admin/member',      // url = base url + request url
        method: 'get',
        params: obj,
        //----------------->>>区别
    })
}
//新增广告接口
export function postaddad(obj,url) {
    return request({
        url: '/api/admin/ad/add',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}
//用户详情接口
export function postediview(obj,url) {
    return request({
        url: '/api/admin/member/view',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}
//删除广告接口
export function postdelad(obj,url) {
    return request({
        url: '/api/admin/ad/del',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}
//提现列表接口
export function getCashList(obj) {
    return request({
        url: '/api/admin/member/getCashList',      // url = base url + request url
        method: 'get',
        params: obj,
        //----------------->>>区别
    })
}
//提现日志接口
export function getCashLog(obj,url) {
    return request({
        url: '/api/admin/member/cashLog',      // url = base url + request url
        method: 'get',
        params: obj,
        //----------------->>>区别
    })
}//师傅广告接口
export function posteditStatus(obj,url) {
    return request({
        url: '/api/admin/member/editStatus',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}
//删除广告接口
export function postAuditStatus(obj,url) {
    return request({
        url: '/api/admin/member/AuditStatus',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}

