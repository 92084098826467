<template>
  <div class="about">
    <div>
      <el-input style="width: 300px;margin: 0 10px 0 0"  v-model="keywords"></el-input>
      <el-button @click="search()" type="primary" style="margin:10px 0;" icon="el-icon-search"></el-button>
    </div>
    <el-table  height="calc(100vh - 310px)" :data="tableData" style="width: 100%;">
      <el-table-column  align="center"
                        type="index"
                        width="55" label="#" >
      </el-table-column>
      <el-table-column prop="real_name" label="姓名">
      </el-table-column>
      <el-table-column width="176" prop="bank_card" label="银行卡号" >
      </el-table-column>
      <el-table-column width="176" prop="bank_name" label="银行" >
      </el-table-column>
      <el-table-column min-width="176" prop="comment" label="备注" >
      </el-table-column>
      <el-table-column prop="user_name" label="操作人" >
      </el-table-column>
      <el-table-column width="176" prop="create_time" label="最后操作时间" >
      </el-table-column>
    </el-table>
    <div style="margin-top: 20px">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-sizes="[10, 20, 30, 40,50]"
          :page-size="limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>

    </div>
    <el-drawer
        title="新增/修改"
        v-model="drawer"
        size="30%"
        direction="rtl"
        :close-on-click-modal="false"
        destroy-on-close>
      <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm">
        <el-form-item label="标签名称" prop="name">
          <el-input v-model="ruleForm.name"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" v-if="ruleForm.id" :loading="login" @click="okAdd('ruleForm',2)">修改</el-button>
          <el-button type="primary" v-else :loading="login" @click="okAdd('ruleForm',1)">保存</el-button>
        </el-form-item>
      </el-form>
    </el-drawer>
  </div>
</template>
<script>
// @ is an alias to /src
import * as axios from "@/api/memberApi"//本页面接口
export default {
  name: 'brand',
  props:['nowData'],
  data(){
    return{
      login:false,
      drawer:false,
      tableData:[],
      page:1,
      limit:10,
      total:0,
      keywords:'',
      ruleForm:{
      },
    }
  },
  created() {
    this.onTable()
    this.keyupSubmit()
  },

  methods:{
    keyupSubmit(){
      document.onkeydown=e=>{
        let _key=window.event.keyCode;
        if(_key===13){
          this.search()
        }
      }
    },
    onTable(){
      let form={
        page:this.page,
        limit:this.limit,
        keywords:this.keywords,
        worker_id:this.nowData.worker_id
      }
      axios.getCashLog(form).then((res)=>{
        console.log(res)
        this.tableData=res.data.data
        console.log('this.tableData',this.tableData)
        this.total=res.data.total
      }).catch((err)=>{

      })
    },
    search(){
      this.page=1
      this.onTable()
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
      this.limit=val
      this.onTable()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
      this.page=val
      this.onTable()
    },
    upData(row){
      axios.postediview({id:row.id}).then((res)=>{
        if(res.code==200){
          this.ruleForm=res.data
          this.drawer=true
        }else {
          this.$message.error(res.msg)
        }
      }).catch((err)=>{
        this.$message.error('服务器报错！！请联系管理员')
      })
    },


  }
}
</script>
<style lang="less" >
.avatar-uploader .el-upload {
  border: 1px dashed #c1c1c1;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.el-upload{

}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-table .el-table__cell{
  padding: 0;
}
</style>